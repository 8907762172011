<template>
  <div class="snackbar-wrapper ml-0 mr-0 pl-0 pr-0">
  </div>
</template>

<script>
import {CLEAR} from '@/store/alerts.module.js';

export default {
  props: {
    type: {type: String, default: ''},
    message: {type: String, default: ''},
  },
  watch: {
    message() {
      if (this.message) {
        this.snackMessage = this.message;
        this.snackbar = this.message && this.message !== "";
        if (this.type === 'success_vote') {
          this.$toasted.success(this.snackMessage, {
            onComplete : this.clearErrorMessage(),
            position: "top-center",
            theme: "toasted-primary",
            duration : 1000
          });
        }
        else if (this.type === 'error_vote') {
          this.$toasted.error(this.snackMessage, {
            onComplete : this.clearErrorMessage(),
            position: "top-center",
            theme: "toasted-primary",
            duration : 1000
          });
        }
        else if (this.type === 'success') {
          this.$toasted.success(this.snackMessage, {
            onComplete : this.clearErrorMessage(),
            theme: "bubble",
            position: "bottom-center",
            duration : 2000
          });
        }
        else {
          this.$toasted.error(this.snackMessage,  {
            onComplete : this.clearErrorMessage(),
            theme: "bubble",
            position: "bottom-center",
            duration : 2000
          });
        }
      }
    },
    // snackbar() {
    //     if (!this.snackbar) {
    //         this.snackMessage = "";
    //     }
    // }
  },
  data () {
    return {
      snackbar: false,
      snackMessage: "",
      multiLine: true,
      timeout: 5000,
    }
  },
  components: {

  },
  methods: {
    clearErrorMessage() {
      this.snackMessage = "";
      this.$store.dispatch(CLEAR);
    },
  }
}
</script>

<style lang="scss">
// .v-btn {
//     width: 100%;
//     text-transform:none !important;
// }

// .v-snack__wrapper {
//     max-width: none;
//     min-width: 50px !important;
// }

// .snackbar-wrapper {
//     pointer-events: none;
//     top: 0;
//     left: 0;
//     z-index: 1000; // <-- For my case
// }

@media only screen and (max-width:600px) {
  .toasted-container .toasted {
    border-radius: 30px!important;
  }

  .toasted-container {
    min-width: 0%!important
  }

  .toasted-container.bottom-center {
    align-items: none!important;
    left: 50%!important;
    bottom: 7%!important;
    transform: translateX(-50%)!important;
  }
}
</style>
