<template>
  <form>
    <!--begin: Head -->
    <div
      class="kt-head kt-head--skin-dark"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <h3 class="kt-head__title">
        User Quick Actions
        <span class="kt-space-15"></span>
        <span class="btn btn-success btn-sm btn-bold btn-font-md"
          >23 tasks pending</span
        >
      </h3>
    </div>
    <!--end: Head -->

    <!--begin: Grid Nav -->
    <div class="kt-grid-nav kt-grid-nav--skin-light">
      <div class="kt-grid-nav__row">
        <a href="#" class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4.362 10.276l.5-1A.5.5 0 015.309 9h9.882a.5.5 0 01.447.724l-.5 1a.5.5 0 01-.447.276H4.809a.5.5 0 01-.447-.724zm10.276 3.448l-.5 1a.5.5 0 01-.447.276H4.809a.5.5 0 01-.447-.724l.5-1A.5.5 0 015.309 13h8.882a.5.5 0 01.447.724z" fill="#000" opacity=".3"/><path d="M17.369 7.618a3.388 3.388 0 00-1.533-1.166 5.079 5.079 0 00-1.848-.367c-.77 0-1.47.14-2.1.42-.63.28-1.172.665-1.627 1.155a5.132 5.132 0 00-1.05 1.722 6.091 6.091 0 00-.368 2.142c0 .812.119 1.554.357 2.226a5.02 5.02 0 001.019 1.732c.44.484.969.858 1.585 1.124.616.266 1.309.399 2.079.399.798 0 1.505-.157 2.121-.473a4.146 4.146 0 001.491-1.249l2.121 1.491a6.796 6.796 0 01-2.415 1.921c-.952.456-2.065.683-3.339.683-1.162 0-2.23-.192-3.203-.578a7.378 7.378 0 01-2.509-1.617 7.361 7.361 0 01-1.638-2.477c-.392-.96-.588-2.02-.588-3.182 0-1.19.206-2.264.62-3.223a7.179 7.179 0 011.7-2.447A7.552 7.552 0 0110.796 4.3c.98-.364 2.044-.546 3.192-.546.476 0 .973.045 1.491.136a7.585 7.585 0 011.491.42c.476.19.924.424 1.344.704.42.28.777.616 1.071 1.008l-2.016 1.596z" fill="#000"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Accounting</span>
          <span class="kt-grid-nav__desc">eCommerce</span>
        </a>
        <a href="#" class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.857 13c.093-.278.143-.574.143-.881V6.881C15 5.29 13.657 4 12 4h-.273C10.221 4 9 5.173 9 6.619h1.09c0-.868.734-1.571 1.637-1.571H12c1.054 0 1.91.82 1.91 1.833v5.238c0 .32-.086.62-.235.881h-3.35a1.768 1.768 0 01-.234-.881V9.5c0-.434.366-.786.818-.786.452 0 .818.352.818.786v1.833h1.091V9.5c0-1.013-.855-1.833-1.909-1.833S9 8.487 9 9.5v2.619c0 .307.05.603.143.881H6a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v9a1 1 0 01-1 1h-3.143z" fill="#000" opacity=".3"/><path d="M9 10.333v1.786C9 13.71 10.343 15 12 15s3-1.29 3-2.881v-1.786l5.207-3.76a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405L9 10.333zm1.09.788L12 12.5l1.91-1.379v.998c0 1.013-.856 1.833-1.91 1.833-1.054 0-1.91-.82-1.91-1.833v-.998z" fill="#000"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Administration</span>
          <span class="kt-grid-nav__desc">Console</span>
        </a>
      </div>
      <div class="kt-grid-nav__row">
        <a href="#" class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M4 9.675l6.88 3.972a.89.89 0 00.231.093v7.704l-6.62-3.918a1 1 0 01-.491-.86V9.675zm16-.106v7.097a1 1 0 01-.49.86l-6.621 3.918v-7.771a.903.903 0 00.048-.026L20 9.569z" fill="#000"/><path d="M4.216 7.747a.999.999 0 01.314-.262l7-3.728a1 1 0 01.94 0l7 3.728c.095.05.18.116.253.191l-7.675 4.431a.893.893 0 00-.14.1.893.893 0 00-.139-.1l-7.553-4.36z" fill="#000" opacity=".3"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Projects</span>
          <span class="kt-grid-nav__desc">Pending Tasks</span>
        </a>
        <a href="#" class="kt-grid-nav__item">
          <span class="kt-grid-nav__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success kt-svg-icon--lg'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M18 14a3 3 0 110-6 3 3 0 010 6zm-9-3a4 4 0 110-8 4 4 0 010 8z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M17.601 15c3.407.038 6.188 1.76 6.397 5.4.009.147 0 .6-.542.6H19.6c0-2.25-.744-4.328-1.999-6zm-17.6 5.2C.388 15.426 4.26 13 8.983 13c4.788 0 8.722 2.293 9.015 7.2.012.195 0 .8-.751.8H.727c-.25 0-.747-.54-.726-.8z" fill="#000" fill-rule="nonzero"/></g></svg>
          </span>
          <span class="kt-grid-nav__title">Customers</span>
          <span class="kt-grid-nav__desc">Latest cases</span>
        </a>
      </div>
    </div>
    <!--end: Grid Nav -->
  </form>
</template>

<script>
export default {
  name: "KTDropdownQuickAction",
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "assets/media/misc/bg-1.jpg";
    }
  }
};
</script>
