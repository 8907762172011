<template>
  <div class="kt-subheader kt-grid__item" id="kt_subheader">
    <div class="kt-container" v-bind:class="{ 'kt-container--fluid': widthFluid }">
      <div class="kt-subheader__main">
        <div class="kt-subheader__breadcrumbs">
          <template v-for="(breadcrumb, i) in breadcrumbs">
            <span class="kt-subheader__title" :key="`${i}-${breadcrumb.id}`">
              {{ breadcrumb.titledad }}
            </span>
            <router-link
              :key="i"
              :to="'/'"
              class="kt-subheader__breadcrumbs-home"
            >
              <i class="flaticon2-shelter"></i
              >
            </router-link>
            <span
                :key="`a-${i}-${breadcrumb.id}`"
                class="kt-subheader__breadcrumbs-separator"
            ></span>
            <span class="kt-subheader__desc" :key="`b-${i}`" v-if="!breadcrumb.route">{{
              breadcrumb.title
            }}</span>
          </template>
        </div>
      </div>
      <div class="kt-subheader__toolbar">
        <div class="kt-subheader__wrapper">
          <a @click="backRouter" class="btn kt-subheader__btn-primary">
            Back &nbsp;
            <!--<i class="flaticon2-calendar-1"></i>-->
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTSubheader",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },
  },
  methods: {
    backRouter() {
      if (
        this.$route.path === "/exercise/detail" ||
        this.$route.path === "/lesson/video"
      ) {
        this.$router.push({
          path: "/course",
          query: {
            course_slug: JSON.parse(localStorage["course_slug"]),
            viewType: JSON.parse(localStorage["viewType"]),
          },
        });
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
